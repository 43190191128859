import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/9QZBo09MOo0">
    <SEO title="Why Do We Gather Every Week? - Why Church?" />
  </Layout>
)
export default SermonPost
